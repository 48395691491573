import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="py-12 bg-gray-50">
      <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {/* Brand Section - Full width on mobile */}
          <div className="text-center sm:text-left">
            <Link 
              to="/"
              onClick={scrollToTop}
              className="inline-block text-blue-600 hover:text-blue-700 font-bold text-xl mb-4"
            >
              DataBackfill
            </Link>
            <p className="text-gray-600 text-sm">
              Simplifying GA4 data synchronization
            </p>
          </div>

          {/* Footer Sections - Stack on mobile */}
          {[
            {
              title: 'Product',
              links: [
                { name: 'Features', path: '/features' },
                { name: 'Pricing', path: '/pricing' },
                { name: 'Documentation', path: '/documentation' },
              ],
            },
            {
              title: 'Company',
              links: [
                { name: 'About', path: '/about' },
                { name: 'Contact', path: '/contact' },
                { name: 'Privacy', path: '/privacypolicy' },
              ],
            },
            {
              title: 'Resources',
              links: [
                { name: 'Blog', path: '/blog' },
                { name: 'Support', path: '/support' },
              ],
            },
          ].map((section, index) => (
            <div key={index} className="text-center sm:text-left">
              <h4 className="font-semibold mb-4">{section.title}</h4>
              <ul className="space-y-2">
                {section.links.map((link, linkIndex) => (
                  <li key={linkIndex}>
                    <Link
                      to={link.path}
                      onClick={scrollToTop}
                      className="text-gray-600 hover:text-blue-600"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Copyright - Centered on all screens */}
        <div className="border-t border-gray-200 mt-12 pt-8 text-center text-gray-600 text-sm">
          <p>© {new Date().getFullYear()} DataBackfill. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;