import React, { useState } from 'react';
import apiClient from '../../utils/apiClient';

const SettingsForm = () => {
  const [credentials, setCredentials] = useState({
    clientId: '',
    clientSecret: '',
    projectId: '',
    datasetId: '',
    tableId: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleCredentialChange = (field, value) => {
    setCredentials(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await apiClient.post('/setup', {
        client_id: credentials.clientId,
        client_secret: credentials.clientSecret,
        project_id: credentials.projectId,
        dataset_id: credentials.datasetId,
        table_id: credentials.tableId
      });

      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      }
    } catch (error) {
      console.error('Setup error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex items-center justify-between mb-6 md:mb-8">
        <div>
          <h1 className="text-xl md:text-2xl font-semibold text-gray-900">Google Cloud Settings</h1>
          <p className="mt-1 text-sm md:text-base text-gray-600">
            Configure your Google Cloud credentials to enable data synchronization
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        <form onSubmit={handleSubmit} className="p-4 md:p-6 space-y-4 md:space-y-6">
          {[
            { label: 'Google Client ID', key: 'clientId', type: 'text' },
            { label: 'Google Client Secret', key: 'clientSecret', type: 'password' },
            { label: 'Google Cloud Project ID', key: 'projectId', type: 'text' },
            { label: 'BigQuery Dataset ID', key: 'datasetId', type: 'text' },
            { label: 'BigQuery Table ID', key: 'tableId', type: 'text' }
          ].map((field) => (
            <div key={field.key}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {field.label}
              </label>
              <input
                type={field.type}
                value={credentials[field.key]}
                onChange={(e) => handleCredentialChange(field.key, e.target.value)}
                className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                         focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base"
                placeholder={`Enter your ${field.label}`}
                required
              />
            </div>
          ))}

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 md:py-3 px-4 rounded-lg font-medium text-sm md:text-base
                     ${isLoading 
                       ? 'bg-gray-300 cursor-not-allowed'
                       : 'bg-blue-600 hover:bg-blue-700 text-white'
                     } transition-colors`}
          >
            {isLoading ? 'Saving...' : 'Save Settings'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SettingsForm;