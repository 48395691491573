import React from 'react';
import { Clock } from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';

const PrivacyPolicy = () => {
  const effectiveDate = "November 7, 2024";
  const companyName = "DataBackfill";
  const contactEmail = "privacy@DataBackfill.com";

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      <div className="pt-20">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Page Title */}
          <div className="py-6 md:py-8">
            <div className="border-b border-gray-200 pb-6 md:pb-8">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-3">Privacy Policy</h1>
              <div className="flex items-center text-gray-600 text-sm md:text-base">
                <Clock className="w-4 h-4 mr-2 shrink-0" />
                <span>Effective Date: {effectiveDate}</span>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="py-6 md:py-8">
            {/* Introduction */}
            <div className="prose prose-gray max-w-none mb-8">
              <p className="text-gray-600 leading-relaxed">
                Your privacy is important to us at {companyName}. This Privacy Policy explains how we collect, use, share, 
                and protect your information when you use our services. We are committed to complying with the General Data 
                Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).
              </p>
            </div>

            {/* Policy Sections */}
            <div className="space-y-8 md:space-y-12">
              {/* Information We Collect */}
              <section>
                <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
                <ul className="space-y-6">
                  <li className="bg-white rounded-lg border border-gray-200 p-4 md:p-6">
                    <span className="block font-medium text-gray-900 mb-2">Personal Information</span>
                    <p className="text-gray-600">
                      This includes your name, email address, billing information, and any other personal information 
                      you provide during registration or when using our services.
                    </p>
                  </li>
                  <li className="bg-white rounded-lg border border-gray-200 p-4 md:p-6">
                    <span className="block font-medium text-gray-900 mb-2">Automatically Collected Information</span>
                    <p className="text-gray-600">
                      We may collect information about your device, browser, IP address, and how you use our website 
                      through cookies and similar technologies.
                    </p>
                  </li>
                  <li className="bg-white rounded-lg border border-gray-200 p-4 md:p-6">
                    <span className="block font-medium text-gray-900 mb-2">Usage Data</span>
                    <p className="text-gray-600">
                      We may track actions you take within our platform, such as backfilling data events or settings changes.
                    </p>
                  </li>
                </ul>
              </section>

              {/* How We Use Your Information */}
              <section>
                <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
                <div className="bg-white rounded-lg border border-gray-200 p-4 md:p-6">
                  <ul className="space-y-4">
                    <li>
                      <span className="block font-medium text-gray-900 mb-2">To Provide and Improve Our Services</span>
                      <p className="text-gray-600">
                        We use your information to operate, maintain, and enhance the features and functionality of our service.
                      </p>
                    </li>
                    <li>
                      <span className="block font-medium text-gray-900 mb-2">Communication</span>
                      <p className="text-gray-600">
                        We may contact you via email or notifications about product updates, marketing communications, 
                        and customer support.
                      </p>
                    </li>
                    <li>
                      <span className="block font-medium text-gray-900 mb-2">Legal Compliance</span>
                      <p className="text-gray-600">
                        We may use your data to comply with applicable laws, regulations, and legal requests.
                      </p>
                    </li>
                  </ul>
                </div>
              </section>

              {/* Simplified Data Rights and Contact */}
              <section>
                <h2 className="text-xl md:text-2xl font-semibold text-gray-900 mb-4">Your Rights and Contact Information</h2>
                <div className="bg-white rounded-lg border border-gray-200 p-4 md:p-6">
                  <p className="text-gray-600 mb-4">
                    Under GDPR and CCPA, you have rights regarding your personal data, including the right to access, 
                    correct, or delete your data. For any privacy-related concerns or to exercise your rights, please 
                    contact us at{' '}
                    <a href={`mailto:${contactEmail}`} className="text-blue-600 hover:text-blue-700">
                      {contactEmail}
                    </a>.
                  </p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;