import React from 'react';
import { 
  Database, 
  Shield, 
  Settings,
  Zap,
  ChevronRight
} from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

const ProductPage = () => {
  const steps = [
    {
      number: "01",
      title: "Connect Your GA4 Property",
      description: "Enter your GA4 Property ID and select the date range for the data you want to backfill.",
      icon: <Database className="w-6 h-6" />
    },
      {
        number: "02",
        title: "Configure BigQuery",
        description: "Follow our intuitive guide to set up your BigQuery connection—no technical headaches, just smooth integration.",
        icon: <Settings className="w-6 h-6" />
      },
      {
        number: "03",
        title: "Start Syncing",
        description: "Click Sync and watch your historical GA4 data flow into BigQuery, ready for analysis and visualization.",
        icon: <Zap className="w-6 h-6" />
      }
    ];
  
    const benefits = [
      {
        title: "Designed for Teams Who Value Data",
        description: "Gain insights effortlessly with a tool designed to make data syncing straightforward and efficient.",
        icon: <Database className="w-6 h-6" />
      },
      {
        title: "Tailored Syncs for Every Need",
        description: "From one-time historical imports to real-time data syncs, customize DataBackfill to fit your data strategy.",
        icon: <Settings className="w-6 h-6" />
      },
      {
        title: "Security and Reliability",
        description: "Your data is always secure, backed by enterprise-grade protection.",
        icon: <Shield className="w-6 h-6" />
      },
      {
        title: "Intuitive & Fast",
        description: "Get up and running in minutes with an easy-to-use interface and step-by-step guidance.",
        icon: <Zap className="w-6 h-6" />
      }
    ];
  
    return (
      <div className="min-h-screen bg-white">
        <Header />
        
        {/* Hero Section - Responsive padding and text sizes */}
        <section className="pt-20 md:pt-32 pb-12 md:pb-24 relative">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-white to-white"></div>
          <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8 relative">
            <div className="max-w-3xl">
              <div className="inline-block px-4 py-1 bg-blue-50 rounded-full text-blue-600 font-medium text-sm mb-4">
                Data Solution
              </div>
              <h1 className="text-3xl md:text-5xl font-bold text-gray-900 mb-4 md:mb-6">
                Discover DataBackfill
              </h1>
              <p className="text-lg md:text-xl text-gray-600 leading-relaxed">
                DataBackfill simplifies historical GA4 data synchronization by seamlessly transferring your Google Analytics 4 data into BigQuery—giving you instant access to valuable insights.
              </p>
            </div>
          </div>
        </section>
  
        {/* Steps Section - Responsive grid */}
        <section className="py-12 md:py-20">
          <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-8 md:mb-16">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">
                Get Started with DataBackfill in 3 Steps
              </h2>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
              {steps.map((step, index) => (
                <div key={index} className="relative">
                  <div className="bg-white rounded-2xl p-6 md:p-8 shadow-lg hover:shadow-xl transition-all">
                    <div className="text-blue-600 font-medium mb-4">{step.number}</div>
                    <div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center mb-6">
                      <div className="text-blue-600">{step.icon}</div>
                    </div>
                    <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
  
        {/* Product Screenshot Section - Responsive padding */}
        <section className="py-12 md:py-20 bg-gray-50">
          <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-8 md:mb-16">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">See DataBackfill in Action</h2>
              <p className="text-lg md:text-xl text-gray-600">
                Experience the simplicity of GA4 data synchronization
              </p>
            </div>
            
            <div className="rounded-2xl bg-white shadow-2xl overflow-hidden">
              <div className="aspect-[16/9] bg-gray-100 flex items-center justify-center">
                <p className="text-gray-500">Product Interface Screenshot</p>
              </div>
            </div>
          </div>
        </section>
  
        {/* Benefits Section - Responsive grid */}
        <section className="py-12 md:py-20">
          <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-8 md:mb-16">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">Why DataBackfill?</h2>
            </div>
  
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-8">
              {benefits.map((benefit, index) => (
                <div key={index} className="bg-white rounded-2xl p-6 md:p-8 border border-gray-100 hover:shadow-lg transition-all">
                  <div className="w-12 h-12 bg-blue-50 rounded-xl flex items-center justify-center mb-6">
                    <div className="text-blue-600">{benefit.icon}</div>
                  </div>
                  <h3 className="text-xl font-semibold mb-4">{benefit.title}</h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
  
        {/* CTA Section - Responsive padding and text */}
        <section className="py-12 md:py-20 bg-gray-50">
          <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
            <div className="bg-blue-600 rounded-3xl p-6 md:p-12 relative overflow-hidden">
              <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500 rounded-full transform translate-x-1/2 -translate-y-1/2 blur-2xl"></div>
              <div className="absolute bottom-0 left-0 w-64 h-64 bg-blue-700 rounded-full transform -translate-x-1/2 translate-y-1/2 blur-2xl"></div>
  
              <div className="relative max-w-lg mx-auto text-center">
                <h2 className="text-2xl md:text-3xl font-bold text-white mb-4 md:mb-6">
                  Ready to elevate your data game?
                </h2>
                <Link 
  to="/pricing"
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
  className="inline-flex items-center px-6 md:px-8 py-3 md:py-4 bg-white text-blue-600 
           rounded-xl text-base md:text-lg font-semibold hover:bg-blue-50 
           transition-all shadow-lg hover:shadow-xl hover:translate-y-[-1px]"
>
  Start Backfilling
  <ChevronRight className="ml-2" size={20} />
</Link>
              </div>
            </div>
          </div>
        </section>
  
        <Footer />
      </div>
    );
  };
  
  export default ProductPage;