import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Check, ArrowRight } from 'lucide-react';

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-white overflow-hidden">
      <Header />
      {/* Pricing Section */}
      <section className="pt-20 md:pt-32 pb-12 md:pb-24">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center mb-8 md:mb-16">
            <div className="inline-block px-4 py-1 bg-blue-50 rounded-full text-blue-600 font-medium text-sm mb-4">
              Simple Pricing
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              Choose the perfect plan for your data needs
            </h2>
            <p className="text-base md:text-xl text-gray-600 max-w-2xl mx-auto">
              Simple, transparent pricing that grows with your analytics requirements
            </p>
          </div>

          {/* Pricing Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            {/* Basic Plan */}
            <div className="bg-white rounded-xl border border-gray-200 p-6 md:p-8 flex flex-col">
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Basic Sync</h3>
                <p className="text-gray-600 text-sm mb-4">Perfect for individuals or small teams</p>
                <div className="flex items-baseline">
                  <span className="text-3xl md:text-4xl font-bold">$99</span>
                  <span className="text-gray-600 ml-2">one-time</span>
                </div>
              </div>
              <button className="w-full py-2 px-4 bg-white border border-gray-200 rounded-lg text-sm font-medium mb-8
                             hover:border-gray-300 transition-colors">
                Get Started
              </button>
              <ul className="space-y-4 flex-grow">
                {[
                  'Single GA4 property sync',
                  'Unlimited historical data sync',
                  'Support via Slack',
                  
                ].map((feature, index) => (
                  <li key={index} className="flex items-start text-sm">
                    <Check className="text-blue-500 w-4 h-4 mr-3 mt-0.5 flex-shrink-0" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Professional Plan - Featured */}
            <div className="bg-white rounded-xl border-2 border-blue-500 p-6 md:p-8 flex flex-col
                          relative shadow-lg transform md:scale-105">
              {/* Popular badge */}
              <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                <div className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                  Most Popular
                </div>
              </div>
              
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Professional Sync</h3>
                <p className="text-gray-600 text-sm mb-4">For growing teams who need more control</p>
                <div className="flex items-baseline">
                  <span className="text-3xl md:text-4xl font-bold">$249</span>
                  <span className="text-gray-600 ml-2">one-time</span>
                </div>
              </div>
              <button className="w-full py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg 
                             text-sm font-medium mb-8 transition-colors">
                Get Started
              </button>
              <ul className="space-y-4 flex-grow">
                {[
                  'Up to 3 GA4 property syncs',
                  'Unlimited historical data sync',
                  'Priority support (24h response) via emal and Slack',
                  
                ].map((feature, index) => (
                  <li key={index} className="flex items-start text-sm">
                    <Check className="text-blue-500 w-4 h-4 mr-3 mt-0.5 flex-shrink-0" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Enterprise Plan */}
            <div className="bg-white rounded-xl border border-gray-200 p-6 md:p-8 flex flex-col">
              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-2">Enterprise</h3>
                <p className="text-gray-600 text-sm mb-4">Custom solutions for large organizations</p>
                <div className="flex items-baseline">
                  <span className="text-3xl md:text-4xl font-bold">Custom</span>
                  <span className="text-gray-600 ml-2">pricing</span>
                </div>
              </div>
              <button className="w-full py-2 px-4 bg-white border border-gray-200 rounded-lg text-sm font-medium mb-8
                             hover:border-gray-300 transition-colors">
                Contact Sales
              </button>
              <ul className="space-y-4 flex-grow">
                {[
                  'Unlimited GA4 properties',
                  'Unlimited historical data sync',
                  'Dedicated support + live chat',
                  'Integration support'
                ].map((feature, index) => (
                  <li key={index} className="flex items-start text-sm">
                    <Check className="text-blue-500 w-4 h-4 mr-3 mt-0.5 flex-shrink-0" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Contact Section */}
          <div className="mt-16 md:mt-20 text-center">
            <p className="text-gray-600 mb-4 px-4">
              Not sure which plan is right for you? Contact our team for a personalized recommendation.
            </p>
            <a 
  href="mailto:sales@databackfill.com"
  className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
>
  Get in touch
  <ArrowRight className="ml-2 w-4 h-4" />
</a>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-12 md:py-20 bg-gray-50">
        <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto">
            <h2 className="text-2xl md:text-3xl font-bold text-center mb-8">
              Frequently Asked Questions
            </h2>
            <div className="space-y-6">
              {[
                {
                  question: "How does the one-time payment work?",
                  answer: "Your one-time payment gives you access to all features within your chosen plan. This includes the ability to sync your historical data and maintain that sync for the specified period."
                },
                {
                  question: "Can I upgrade my plan later?",
                  answer: "Yes, you can upgrade to a higher tier plan at any time. We'll help you transition smoothly and maintain all your existing syncs and configurations."
                },
                {
                  question: "What happens after my sync period ends?",
                  answer: "After your sync period ends, you can choose to renew your plan or upgrade to a different tier. Your historical data remains accessible in BigQuery."
                }
              ].map((faq, index) => (
                <div key={index} className="bg-white rounded-lg border border-gray-200 p-6">
                  <h3 className="text-lg font-semibold mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default PricingPage;