import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSession, useDescope } from '@descope/react-sdk';
import { LogOut, Menu, X } from 'lucide-react';

const Header = () => {
  const { isAuthenticated } = useSession();
  const { logout } = useDescope();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/');
    setIsMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to handle navigation and scroll
  const handleNavigation = (path) => {
    navigate(path);
    setIsMenuOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className="bg-white/90 backdrop-blur-sm fixed w-full z-50 border-b border-gray-200">
      <div className="max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Brand Name */}
          <Link 
            to="/" 
            onClick={() => {
              setIsMenuOpen(false);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="text-blue-600 font-bold text-xl"
          >
            DataBackfill
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button 
              onClick={() => handleNavigation('/')} 
              className="text-gray-600 hover:text-gray-900"
            >
              Home
            </button>
            <button 
              onClick={() => handleNavigation('/product')} 
              className="text-gray-600 hover:text-gray-900"
            >
              Product
            </button>
            <button 
              onClick={() => handleNavigation('/features')} 
              className="text-gray-600 hover:text-gray-900"
            >
              Features
            </button>
            {isAuthenticated ? (
              <button
                onClick={handleLogout}
                className="flex items-center text-gray-600 hover:text-gray-900"
              >
                <LogOut size={20} />
                <span className="ml-1">Logout</span>
              </button>
            ) : (
              <button
                onClick={() => handleNavigation('/auth')}
                className="px-4 py-1.5 border border-gray-200 rounded-full text-sm 
                         hover:border-blue-500 hover:text-blue-500 transition-colors"
              >
                Log in
              </button>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-lg text-gray-600 hover:bg-gray-100"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden bg-white border-t border-gray-200">
          <div className="px-4 pt-2 pb-4 space-y-1">
            <button
              onClick={() => handleNavigation('/')}
              className="block w-full px-4 py-3 text-left text-gray-600 hover:bg-gray-50 rounded-lg"
            >
              Home
            </button>
            <button
              onClick={() => handleNavigation('/product')}
              className="block w-full px-4 py-3 text-left text-gray-600 hover:bg-gray-50 rounded-lg"
            >
              Product
            </button>
            <button
              onClick={() => handleNavigation('/features')}
              className="block w-full px-4 py-3 text-left text-gray-600 hover:bg-gray-50 rounded-lg"
            >
              Features
            </button>
            {isAuthenticated ? (
              <button
                onClick={handleLogout}
                className="w-full flex items-center px-4 py-3 text-gray-600 hover:bg-gray-50 rounded-lg"
              >
                <LogOut size={20} />
                <span className="ml-2">Logout</span>
              </button>
            ) : (
              <button
                onClick={() => handleNavigation('/auth')}
                className="block w-full px-4 py-3 text-center text-blue-600 hover:bg-blue-50 rounded-lg"
              >
                Log in
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Header;