import React from 'react';

const DashboardHome = ({ data }) => {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-xl md:text-2xl font-semibold">Dashboard Overview</h1>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
          <div className="text-sm text-gray-600">Total Syncs</div>
          <div className="text-xl md:text-2xl font-semibold mt-2">{data.totalSyncs || 0}</div>
        </div>
        
        <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
          <div className="text-sm text-gray-600">Active Properties</div>
          <div className="text-xl md:text-2xl font-semibold mt-2">{data.activeProperties?.length || 0}</div>
        </div>
        
        <div className="bg-white p-4 md:p-6 rounded-lg border border-gray-200">
          <div className="text-sm text-gray-600">Last Sync</div>
          <div className="text-xl md:text-2xl font-semibold mt-2">
            {data.lastSync?.status || 'No syncs yet'}
          </div>
        </div>
      </div>

      {/* Recent Activity */}
      <div className="bg-white rounded-lg border border-gray-200">
        <div className="p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Recent Activity</h2>
        </div>
        <div className="p-4">
          {data.recentActivity && data.recentActivity.length > 0 ? (
            <div className="space-y-4">
              {data.recentActivity.map((activity, index) => (
                <div key={index} className="flex flex-col sm:flex-row sm:justify-between sm:items-center py-2">
                  <div className="mb-2 sm:mb-0">
                    <p className="text-sm">{activity.message}</p>
                    <p className="text-xs text-gray-500">Property ID: {activity.propertyId}</p>
                  </div>
                  <div className="text-xs text-gray-400">{activity.timeAgo}</div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-center py-4">No recent activity</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;