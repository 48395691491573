const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'http://127.0.0.1:5000'
  : 'https://api.databackfill.com';

const apiClient = {
  request: async (endpoint, options = {}) => {
    const token = localStorage.getItem('sessionToken');
    
    const defaultHeaders = {
      'Content-Type': 'application/json',
      ...(token && { 'Authorization': `Bearer ${token}` })
    };

    const config = {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
        // Ensure Authorization header is included even if in options.headers
        ...(token && { 'Authorization': `Bearer ${token}` })
      },
      credentials: 'include'
    };

    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, config);
      
      // Handle 401 Unauthorized
      if (response.status === 401) {
        localStorage.removeItem('sessionToken');
        window.location.href = '/auth';
        throw new Error('Unauthorized');
      }

      // Handle non-2xx responses
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  },

  get: (endpoint) => {
    return apiClient.request(endpoint, { method: 'GET' });
  },

  post: (endpoint, data) => {
    return apiClient.request(endpoint, {
      method: 'POST',
      body: JSON.stringify(data)
    });
  },

  postFormData: async (endpoint, formData) => {
    const token = localStorage.getItem('sessionToken');
    
    try {
      const response = await fetch(`${BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          ...(token && { 'Authorization': `Bearer ${token}` })
        },
        body: formData,
        credentials: 'include'
      });

      if (response.status === 401) {
        localStorage.removeItem('sessionToken');
        window.location.href = '/auth';
        throw new Error('Unauthorized');
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }
};

export default apiClient;