import React from 'react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Mail } from 'lucide-react';

const TermsOfUse = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      <div className="pt-20">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="py-8 md:py-12">
            <div className="text-center mb-8 md:mb-12">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">Terms of Use</h1>
              <p className="mt-4 text-gray-600">Last updated: November 8, 2024</p>
            </div>

            {/* Terms Content */}
            <div className="bg-white rounded-2xl shadow-sm border border-gray-200">
              <div className="p-6 md:p-8 space-y-8">
                {/* Section 1 */}
                <section id="acceptance">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    By accessing or using the DataBackfill service ("the Service"), you agree to be bound by these Terms of Use ("Terms"). 
                    If you do not agree with any part of these Terms, you must not access or use the Service.
                  </p>
                </section>

                {/* Section 2 */}
                <section id="description">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">2. Description of the Service</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    The Service provides tools to backfill Google Analytics 4 (GA4) data into BigQuery. 
                    We strive to ensure reliable, efficient, and secure data synchronization for your business needs.
                  </p>
                </section>

                {/* Section 3 */}
                <section id="obligations">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">3. User Obligations</h2>
                  <p className="text-gray-600 mb-4 text-sm md:text-base">As a user of the Service, you agree to:</p>
                  <ul className="list-disc pl-6 space-y-2 text-gray-600 text-sm md:text-base">
                    <li>Provide accurate information during registration and use.</li>
                    <li>Keep your login credentials secure and confidential.</li>
                    <li>Comply with all applicable laws and regulations.</li>
                    <li>Avoid using the Service for unlawful or harmful purposes, including interfering with the Service's operation.</li>
                  </ul>
                </section>

                {/* Section 4 */}
                <section id="privacy">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">4. Data Privacy and Security</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    We value your privacy. Please review our Privacy Policy to understand how we collect, use, and protect your data. 
                    By using the Service, you agree that we can collect, store, and process your data as described in the Privacy Policy.
                  </p>
                </section>

                {/* Section 5 */}
                <section id="registration">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">5. Account Registration</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    To use certain features of the Service, you may be required to create an account. 
                    You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.
                  </p>
                </section>

                {/* Section 6 */}
                <section id="restrictions">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">6. Usage Restrictions</h2>
                  <p className="text-gray-600 mb-4 text-sm md:text-base">You agree not to:</p>
                  <ul className="list-disc pl-6 space-y-2 text-gray-600 text-sm md:text-base">
                    <li>Copy, modify, or create derivative works of the Service.</li>
                    <li>Reverse-engineer, decompile, or attempt to extract the source code of the Service.</li>
                    <li>Use automated means to access or use the Service (e.g., bots, scripts).</li>
                  </ul>
                </section>

                {/* Section 7 */}
                <section id="ip-rights">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">7. Intellectual Property Rights</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    All content, features, and functionality of the Service, including but not limited to text, graphics, 
                    software, and logos, are owned by or licensed to DataBackfill and are protected by intellectual property laws.
                  </p>
                </section>

                {/* Section 8 */}
                <section id="termination">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">8. Termination of Access</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    We reserve the right to suspend or terminate your access to the Service at any time, without notice, 
                    for any reason, including but not limited to a breach of these Terms.
                  </p>
                </section>

                {/* Section 9 */}
                <section id="disclaimer">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">9. Disclaimer of Warranties</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    The Service is provided "as is" and "as available." We make no warranties, expressed or implied, 
                    regarding the Service's availability, reliability, or suitability for your needs. You use the Service at your own risk.
                  </p>
                </section>

                {/* Section 10 */}
                <section id="liability">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">10. Limitation of Liability</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    To the maximum extent permitted by law, DataBackfill and its affiliates shall not be liable for any direct, 
                    indirect, incidental, special, or consequential damages arising from your use or inability to use the Service.
                  </p>
                </section>

                {/* Section 11 */}
                <section id="changes">
                  <h2 className="text-lg md:text-xl font-semibold text-gray-900 mb-4">11. Changes to Terms</h2>
                  <p className="text-gray-600 leading-relaxed text-sm md:text-base">
                    We may update these Terms from time to time. Any changes will be effective upon posting to the Service. 
                    Your continued use of the Service signifies your acceptance of the revised Terms.
                  </p>
                </section>

                {/* Contact Section */}
                <section id="contact" className="mt-12 bg-gray-50 rounded-xl p-4 md:p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <Mail className="text-blue-600 shrink-0" />
                    <h2 className="text-lg md:text-xl font-semibold text-gray-900">Contact Information</h2>
                  </div>
                  <p className="text-gray-600 text-sm md:text-base">
                    If you have any questions or concerns about these Terms, please contact us at{' '}
                    <a href="mailto:support@databackfill.com" className="text-blue-600 hover:text-blue-700">
                      support@databackfill.com
                    </a>
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsOfUse;