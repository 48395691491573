import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { X } from 'lucide-react';

const PrivacyPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if user has already accepted cookies
    const hasAccepted = localStorage.getItem('cookieConsent');
    if (!hasAccepted) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black/20 backdrop-blur-sm" />
      
      {/* Popup Content */}
      <div className="relative bg-white border-t border-gray-200 shadow-lg">
        <div className="max-w-7xl mx-auto p-4 sm:p-6">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0 sm:space-x-6">
            <div className="flex-1 pr-8">
              <h3 className="text-base sm:text-lg font-semibold text-gray-900 mb-2">
                We Value Your Privacy
              </h3>
              <p className="text-sm text-gray-600 leading-relaxed mb-2">
                We use cookies and other technologies to collect information about your interactions 
                with our website. This helps us provide you with the best experience possible, analyze site usage, 
                and assist in our marketing efforts.
              </p>
              <div className="text-sm text-gray-600">
                By clicking Accept, you agree to our{' '}
                <Link 
                  to="/terms" 
                  className="text-blue-600 hover:text-blue-700 underline"
                  onClick={() => setIsOpen(false)}
                >
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link 
                  to="/privacypolicy" 
                  className="text-blue-600 hover:text-blue-700 underline"
                  onClick={() => setIsOpen(false)}
                >
                  Privacy Policy
                </Link>.
              </div>
            </div>
            <div className="flex items-center space-x-4 shrink-0 w-full sm:w-auto">
              <button
                onClick={handleAccept}
                className="w-full sm:w-auto px-4 sm:px-6 py-2 bg-blue-600 text-white rounded-lg 
                         font-medium hover:bg-blue-700 transition-colors whitespace-nowrap"
              >
                Accept
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="p-2 text-gray-400 hover:text-gray-600 transition-colors"
                aria-label="Close"
              >
                <X size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPopup;