import React, { useState } from 'react';
import apiClient from '../../utils/apiClient';

const SyncForm = ({ onSyncComplete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [syncData, setSyncData] = useState({
    propertyId: '',
    startDate: '',
    endDate: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const formData = new FormData();
      formData.append('property_id', syncData.propertyId);
      formData.append('start_date', syncData.startDate);
      formData.append('end_date', syncData.endDate);

      await apiClient.postFormData('/backfill', formData);
      
      // Clear form
      setSyncData({
        propertyId: '',
        startDate: '',
        endDate: ''
      });

      // Notify parent component to refresh dashboard data
      if (onSyncComplete) {
        onSyncComplete();
      }
    } catch (error) {
      console.error('Sync error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="flex items-center justify-between mb-6 md:mb-8">
        <div>
          <h1 className="text-xl md:text-2xl font-semibold text-gray-900">Data Sync</h1>
          <p className="mt-1 text-sm md:text-base text-gray-600">
            Configure your GA4 data sync settings
          </p>
        </div>
      </div>

      <div className="bg-white rounded-lg border border-gray-200 shadow-sm">
        <form onSubmit={handleSubmit} className="p-4 md:p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              GA4 Property ID
            </label>
            <input
              type="text"
              value={syncData.propertyId}
              onChange={(e) => setSyncData(prev => ({ ...prev, propertyId: e.target.value }))}
              className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                       focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base"
              placeholder="Enter your GA4 Property ID"
              required
            />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Start Date
              </label>
              <input
                type="date"
                value={syncData.startDate}
                onChange={(e) => setSyncData(prev => ({ ...prev, startDate: e.target.value }))}
                className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                         focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                End Date
              </label>
              <input
                type="date"
                value={syncData.endDate}
                onChange={(e) => setSyncData(prev => ({ ...prev, endDate: e.target.value }))}
                className="w-full p-2 md:p-3 border border-gray-300 rounded-lg focus:ring-2 
                         focus:ring-blue-500 focus:border-blue-500 text-sm md:text-base"
                required
              />
            </div>
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className={`w-full py-2 md:py-3 px-4 rounded-lg font-medium text-sm md:text-base
                     ${isLoading 
                       ? 'bg-gray-300 cursor-not-allowed'
                       : 'bg-blue-600 hover:bg-blue-700 text-white'
                     } transition-colors`}
          >
            {isLoading ? 'Starting Sync...' : 'Start Sync'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SyncForm;