import React, { useState, useEffect } from 'react';
import { Settings, HelpCircle, Home, Database, LogOut, X, Menu } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSession, useDescope } from '@descope/react-sdk';
import { toast } from 'react-toastify';
import apiClient from '../utils/apiClient';

// Import your existing component files
import DashboardHome from './dashboard/dashboardhome';
import SettingsForm from './dashboard/settingsform';
import SyncForm from './dashboard/syncform';

const DataSyncDashboard = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    lastSync: { status: 'Completed', timeAgo: '2m ago' },
    totalSyncs: 0,
    activeProperties: 0,
    recentActivity: [],
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated } = useSession();
  const { logout } = useDescope();

  // Fetch dashboard data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await apiClient.get('/api/data');
        setDashboardData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Check authentication
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth');
    }
  }, [isAuthenticated, navigate]);

  // Handle logout
  const handleLogout = () => {
    logout();
    toast.info('You have been logged out.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    navigate('/');
  };

  // Handle when sync is completed to refresh dashboard data
  const handleSyncComplete = async () => {
    try {
      const data = await apiClient.get('/api/data');
      setDashboardData(data);
    } catch (error) {
      console.error('Error refreshing dashboard data:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="bg-white border-b border-gray-200">
        <div className="flex items-center justify-between px-4 md:px-6 py-4">
          <div className="flex items-center space-x-4">
            {/* Mobile menu button */}
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="md:hidden p-2 rounded-lg text-gray-600 hover:bg-gray-100"
            >
              {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
            <div className="text-blue-600 font-bold text-xl">DataBackfill</div>
          </div>
          <div className="flex items-center space-x-4">
            <button 
              onClick={() => navigate('/documentation')} 
              className="text-gray-600 hover:text-blue-600 hidden sm:block"
              title="Documentation"
            >
              <HelpCircle size={20} />
            </button>
            <button
              onClick={handleLogout}
              className="text-gray-600 hover:text-blue-600 flex items-center"
            >
              <LogOut size={20} />
              <span className="ml-1 hidden sm:inline">Logout</span>
            </button>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="flex min-h-[calc(100vh-64px)]">
        {/* Sidebar - Mobile Drawer / Desktop Sidebar */}
        <div
          className={`
            fixed md:relative inset-0 z-30 md:z-auto
            transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0
            transition-transform duration-300 ease-in-out
            bg-white w-64 border-r border-gray-200
            ${isSidebarOpen ? 'block' : 'hidden'} md:block
          `}
        >
          {/* Overlay for mobile */}
          {isSidebarOpen && (
            <div 
              className="fixed inset-0 bg-black/20 md:hidden" 
              onClick={() => setIsSidebarOpen(false)}
            />
          )}

          {/* Sidebar Content */}
          <div className="relative z-10 h-full bg-white p-4">
            <div className="space-y-2">
              <button
                onClick={() => {
                  setActiveTab('home');
                  setIsSidebarOpen(false);
                }}
                className={`flex items-center space-x-2 w-full p-3 rounded-lg ${
                  activeTab === 'home'
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Home size={20} />
                <span>Dashboard</span>
              </button>
              <button
                onClick={() => {
                  setActiveTab('sync');
                  setIsSidebarOpen(false);
                }}
                className={`flex items-center space-x-2 w-full p-3 rounded-lg ${
                  activeTab === 'sync'
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Database size={20} />
                <span>Data Sync</span>
              </button>
              <button
                onClick={() => {
                  setActiveTab('settings');
                  setIsSidebarOpen(false);
                }}
                className={`flex items-center space-x-2 w-full p-3 rounded-lg ${
                  activeTab === 'settings'
                    ? 'bg-blue-50 text-blue-600'
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                <Settings size={20} />
                <span>Google Cloud</span>
              </button>
            </div>
          </div>
        </div>

        {/* Content Area */}
        <div className="flex-1 p-4 md:p-6 overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">
              Error loading dashboard: {error}
            </div>
          ) : (
            <>
              {activeTab === 'home' && <DashboardHome data={dashboardData} />}
              {activeTab === 'sync' && (
                <SyncForm onSyncComplete={handleSyncComplete} />
              )}
              {activeTab === 'settings' && <SettingsForm />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataSyncDashboard;