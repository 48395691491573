import React, { useState, useEffect } from 'react';
import { Search, ChevronRight, Mail, HelpCircle, ExternalLink, Menu, X, ChevronDown } from 'lucide-react';
import Header from './shared/Header';
import Footer from './shared/Footer';
import { Link } from 'react-router-dom';

const DocumentationPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  // Navigation structure with IDs
  const sections = [
    {
      title: 'Getting Started',
      items: [
        { name: 'Introduction', id: 'introduction' },
        { name: 'Account Setup', id: 'account-setup' },
        { name: 'Prerequisites', id: 'prerequisites' },
        { name: 'Quick Start Guide', id: 'quick-start' }
      ]
    },
    {
      title: 'Configuration',
      items: [
        { name: 'Connecting GA4', id: 'connecting-ga4' },
        { name: 'BigQuery Setup', id: 'bigquery-setup' },
        { name: 'OAuth Configuration', id: 'oauth-config' }
      ]
    },
    {
      title: 'Features',
      items: [
        { name: 'Real-Time Processing', id: 'real-time-processing' },
        { name: 'Historical Data Sync', id: 'historical-sync' },
        { name: 'Security', id: 'security' },
        { name: 'Integrations', id: 'integrations' }
      ]
    },
    {
      title: 'Advanced',
      items: [
        { name: 'API Configuration', id: 'api-config' },
        { name: 'Service Accounts', id: 'service-accounts' },
        { name: 'Troubleshooting', id: 'troubleshooting' }
      ]
    }
  ];

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  // Handle smooth scrolling
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      // Close mobile sidebar if open
      setIsSidebarOpen(false);
      
      // Add offset for fixed header
      const offset = 100; // Adjust this value based on your header height
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  // Handle hash changes
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (hash) {
        setTimeout(() => {
          scrollToSection(hash);
        }, 100);
      }
    };

    // Handle hash on initial load
    handleHashChange();

    // Add listener for hash changes
    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      
      {/* Documentation Header */}
      <div className="pt-16 md:pt-20">
        <div className="bg-white border-b border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-6 md:py-8">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">Documentation</h1>
              <p className="mt-2 text-base md:text-lg text-gray-600">Learn how to use DataBackfill effectively</p>
            </div>
          </div>
        </div>
      </div>

      {/* Future Search Bar */}
      

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <div className="lg:flex lg:gap-8">
          {/* Mobile Sidebar Toggle */}
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="lg:hidden w-full mb-4 flex items-center justify-between px-4 py-2 bg-white rounded-lg border border-gray-200"
          >
            <span className="font-medium text-gray-900">Documentation Menu</span>
            {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
          </button>

          {/* Side Navigation */}
          <div className={`
            lg:w-64 lg:block lg:shrink-0
            fixed lg:static inset-0 z-30 lg:z-0
            transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0
            transition-transform duration-300 ease-in-out
            bg-white lg:bg-transparent
            ${isSidebarOpen ? 'block' : 'hidden'}
          `}>
            {/* Mobile Overlay */}
            <div 
              className={`fixed inset-0 bg-black/20 lg:hidden ${isSidebarOpen ? 'block' : 'hidden'}`}
              onClick={() => setIsSidebarOpen(false)}
            />

            {/* Sidebar Content */}
            <div className="relative h-full lg:h-auto bg-white p-4 overflow-y-auto lg:sticky lg:top-28">
              <div className="space-y-6">
                {sections.map((section, index) => (
                  <div key={index}>
                    <button
                      onClick={() => toggleSection(index)}
                      className="flex items-center justify-between w-full text-left font-semibold text-gray-900 hover:text-blue-600"
                    >
                      <span>{section.title}</span>
                      <ChevronDown 
                        size={16} 
                        className={`transform transition-transform ${expandedSection === index ? 'rotate-180' : ''}`}
                      />
                    </button>
                    <div className={`mt-2 space-y-1 ${expandedSection === index ? 'block' : 'hidden'} lg:block`}>
                      {section.items.map((item, itemIndex) => (
                        <button
                          key={itemIndex}
                          onClick={() => scrollToSection(item.id)}
                          className="block w-full text-left pl-4 py-2 text-sm text-gray-600 hover:text-blue-600 hover:bg-gray-50 rounded-lg"
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 max-w-3xl">
            {/* Introduction */}
            <section id="introduction" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">What is DataBackfill?</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                DataBackfill is a powerful tool designed to simplify the process of synchronizing historical Google Analytics 4 (GA4) 
                data with BigQuery.
              </p>
              
              <div className="bg-blue-50 rounded-lg p-4 md:p-6 mb-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-2">How It Works</h3>
                <p className="text-gray-600">
                  DataBackfill connects to your GA4 property and BigQuery, allowing you to retrieve historical data and perform 
                  real-time data synchronization.
                </p>
              </div>
            </section>

            {/* Account Setup */}
            <section id="account-setup" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Account Setup</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                Getting started with DataBackfill is quick and easy. Follow these steps to set up your account.
              </p>
            </section>

            {/* Prerequisites */}
            <section id="prerequisites" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Prerequisites</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                Before you begin, ensure you have the following requirements met.
              </p>
            </section>

            {/* Add more sections with their IDs matching the navigation */}
            <section id="troubleshooting" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">Troubleshooting</h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                Common issues and their solutions.
              </p>
            </section>

            {/* Support Section */}
            <section id="support" className="mb-12 scroll-mt-28">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Need Help?</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <Mail className="text-blue-600" size={24} />
                    <h3 className="font-semibold text-gray-900">Email Support</h3>
                  </div>
                  <p className="text-gray-600 mb-4">Get in touch with our support team</p>
                  <a href="mailto:support@DataBackfill.com" className="text-blue-600 hover:text-blue-700 font-medium">
                    support@DataBackfill.com
                  </a>
                </div>
                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  <div className="flex items-center space-x-3 mb-4">
                    <HelpCircle className="text-blue-600" size={24} />
                    <h3 className="font-semibold text-gray-900">Help Center</h3>
                  </div>
                  <p className="text-gray-600 mb-4">Browse our knowledge base</p>
                  <Link 
  to="/support" 
  className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
>
  Visit Help Center
  <ExternalLink size={16} className="ml-1" />
</Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DocumentationPage;